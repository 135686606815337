<template>
  <div class="c-empty">
    <h3><t name="1.7.2" /></h3>
  </div>
</template>

<script>

export default {
  name: 'CCircleStats',
}
</script>

<style lang="scss" scoped>
.c-empty {
  h3 {
    margin-top: 70%;
    text-align: center;
    color: #A6A6A6;
  }
}
</style>
