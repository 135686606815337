<template>
  <span class="t" v-html="$app.secure.clean($n.t(name))" />
</template>

<script>
export default {
  name: 'Index',
  props: {
    name: { type: String, required: true, },
  },
}
</script>

<style lang="scss" scoped>
.t {

}
</style>
