<template>
  <div>
    <h1>{{ countdown }}</h1>
  </div>
</template>

<script>
export default {
  name: "CCountdownTimer",
  props: {
    targetDate: null,
  },
  data() {
    return {
      countdown: "",
    };
  },
  methods: {
    updateCountdown() {
      const now = new Date();
      const timeDifference = this.$props.targetDate - now;

      if (timeDifference <= 0) {
        this.countdown = "00 : 00 : 00 : 00";
        return;
      }

      const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor(
        (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
      );
      const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

      this.countdown = this.formatTime(days, hours, minutes, seconds);
    },

    formatTime(days, hours, minutes, seconds) {
      return `${this.formatTimeFragments(days)} : ${this.formatTimeFragments(
        hours
      )} : ${this.formatTimeFragments(minutes)} : ${this.formatTimeFragments(
        seconds
      )}`;
    },

    formatTimeFragments(fragment) {
      return fragment < 10 ? "0" + fragment : fragment;
    },
  },
  mounted() {
    this.updateCountdown(); // Initial call
    this.interval = setInterval(this.updateCountdown, 1000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>

<style scoped lang="scss">
/* Add your custom styles here */
</style>
