<template>
  <div class="c-frame">
    <div class="frame-box">
      <div class="goback-place">
        <c-go-back class="frame-go-back" :backroute="bacroute" title="" @click="closeForm"></c-go-back>
      </div>
      <iframe class="frame" :src="url"></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CFrame',
  props: {
    url: {
      type: String,
      required: true,
    },
    bacroute: {
      type: String,
      required: true,
    },
  },
  created() {},
  methods: {
    closeForm() {
      this.$emit('update:show', false)
    },
  },
}
</script>

<style lang="scss" scoped>
.c-frame {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 1001;
  background-color: #FFFFFF;
  padding-top: env( safe-area-inset-top);

  .goback-place {
    position: relative;
    width: 100vw;
    padding: 0 25px;

    .c-go-back{
      background: transparent;
      &::after{
        background: transparent;
      }
    }
    //.frame-go-back {
    //  position: absolute;
    //  left: 15px;
    //  bottom: -35px;
    //}
  }
  .frame-box {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 15px;
    .frame-button {
      margin: 25px 20px 0 20px;
      width: 90%;
      background-color: var(--primary);
      height: 44px;
      border-radius: 12px;
      ::v-deep .content {
        color: #FFFFFF;
      }
    }
    .frame {
      width: 100%;
      height: 90%;
      border: none;
    }
  }
}
</style>
