<template>
  <div class="c-modal-contacts">
    <div class="container">
      <div class="contacts">
        <n-loader :loading="$var('load')" />
        <h3><t name="1.8.3" /></h3>
        <div class="contact">
          <div class="image-place">
            <img :src="imgWhatsapp" alt="">
          </div>
          <a @click="openWhatsapp()">ProsperPay</a>
        </div>
        <div class="contact">
          <div class="image-place">
            <img :src="imgInstagram" alt="">
          </div>
          <a @click="openInstagram()">ProsperPay</a>
        </div>
<!--        <div class="contact">-->
<!--          <div class="image-place">-->
<!--            <img :src="imgTelegram" alt="">-->
<!--          </div>-->
<!--          <a @click="openTelegram()">ProsperPay</a>-->
<!--        </div>-->
                <div class="contact">
                  <div class="image-place">
                    <img :src="imgPhone" alt="">
                  </div>
                  <a @click="openPhone()">ProsperPay</a>
                </div>
        <div class="button-place">
          <n-button class="user-default-button" @click="close">Назад</n-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import imgPhone from 'assets/contacts/phone.svg'
import imgWhatsapp from 'assets/contacts/whatsapp.svg'
import imgTelegram from 'assets/contacts/telegram.svg'
import imgInstagram from 'assets/contacts/instagram.svg'

export default {
  name: 'CModalContacts',
  data() {
    return {
      contacts: [],
      imgPhone,
      imgWhatsapp,
      imgTelegram,
      imgInstagram,
    }
  },
  created() {},
  methods: {
    openWhatsapp() {
      window.location.href = 'https://wa.me/77017654534?text=Здравствуйте!%0AУ меня есть вопрос.'
    },
    openInstagram() {
      window.location.href = 'https://www.instagram.com/'
    },
    openTelegram() {
      window.location.href = 'https://t.me/skalskiy_d'
    },
    openPhone() {
      window.location.href = 'tel:88000708068'
    },
    close() {
      this.$emit('update:show', false)
    },
  },
}
</script>

<style lang="scss" scoped>

.c-modal-contacts {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 1001;
  padding-top: 50px;
  background-color: #fafafa;;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .contacts {
    position: relative;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h3 {
      font-weight: bold;
    }

    .contact {
      width: 100%;
      padding: 10px;
      border-radius: 8px;
      background-color: var(--secondary);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;
      .image-place {
        width: 24px;
        height: 24px;
        margin-right: 15px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      a {
        color: #FFFFFF;
        text-decoration: none;
      }

    }
    .button-place {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 30px;
      .user-default-button {
        width: 200px;
        height: 44px;
        background-color: var(--primary);
        border-radius: 8px;
        color: #ffffff;
        font-size: 1.1em;
        font-weight: bold;
      }
    }
  }

}
</style>
