<template>
  <div class="c-change-pin">
    <h2><t name="1.11.10" /></h2>
    <n-loader :loading="$var('load')" />
    <c-pin v-if="step === 0" :danger-message="$var('code0Message')" :value.sync="code0" :title="$app.secure.clean($n.t('1.11.11'))"></c-pin>
    <c-pin v-if="step === 1" :value.sync="code1" :title="$app.secure.clean($n.t('1.11.13'))"></c-pin>
    <c-pin v-if="step === 2" :danger-message="$var('code2Message')" :value.sync="code2" :title="$app.secure.clean($n.t('1.11.14'))"></c-pin>
    <div class="buttons">
      <n-button v-if="step !== 0" class="user-default-button" @click="back"><t name="1.11.15" /></n-button>
      <n-button class="user-default-button gray" @click="close"><t name="1.11.12" /></n-button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CChangePin',
  data() {
    return {
      step: 0,
      code0: '',
      code1: '',
      code2: '',
      user: $app.auth.user(),
    }
  },
  watch: {
    code0() {
      if (this.code0.length === 4) {
        if (this.code0 !== this.user.pin) {
          this.$var('code0Message', $app.secure.clean($n.t('3.9')))
        } else {
          this.step = 1
        }
      }
    },
    code1() {
      if (this.code1.length === 4) {
        this.step = 2
        this.code2 = ''
        this.$var('code2Message', '')
      }
    },
    code2() {
      if (this.code2.length === 4) {
        if (this.code2 !== this.code1) {
          this.$var('code2Message', $app.secure.clean($n.t('3.13')))
        } else {
          this.editPin()
        }
      }
    },
  },
  methods: {
    close() {
      this.$emit('update:show', false)
    },
    back() {
      this.step = this.step-1
      this['code'+this.step] = ''
      this.$var('code'+this.step+'Message', '')
    },
    editPin() {
      this.$var('load', true)
      $api.users.editPin($app.auth.user().id, {
        oldPin: this.code0,
        newPin: this.code1,
        confirmPin: this.code2,
      }).then(() => {
        $app.store.action('app.updateUser').finally(() => {
          this.close()
          this.$var('load', false)
        })
      }).catch((response) => {
        $app.ui.notify(response.response.data.message, 'danger')
        this.close()
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.c-change-pin {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #f1f1f1;
  z-index: 101;
  padding: 25px;
  .buttons {
    width: 100%;
  }
  h2 {
    font-size: 1.1em;
    font-weight: bold;
  }

}
</style>
