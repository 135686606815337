<template>
  <div class="c-circle-stats">
    <div class="circle-empty">
      <div class="outside-circle">
        <div class="circle" :style="{'--c':color, '--p':percent,}"></div>
      </div>
      <div class="inside-circle" :style="{'color':textColor}">
        {{ centerText + ' ₸' }}
      </div>
    </div>
    <h3>{{ title }}</h3>
  </div>
</template>

<script>

export default {
  name: 'CCircleStats',
  props: {
    color: {
      type: String,
      default: 'var(--primary)',
    },
    textColor: {
      type: String,
      default: 'var(--primary)',
    },
    centerText: {
      type: String,
      required: true,
    },
    finishValue: {
      type: Number,
      required: true,
    },
    currentValue: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      default: 'Заработано',
    },
  },
  data() {
    return {
      percent: 0,
    }
  },
  watch: {},
  created() {
    this.calcPercent()
  },
  methods: {
    calcPercent() {
      this.percent = Math.round(this.currentValue/this.finishValue*100)
    },
  },
}
</script>

<style lang="scss" scoped>
.c-circle-stats {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .circle-empty {
    position: relative;
    width: 160px;
    height: 160px;
    background-color: #E5E6E8;
    border-radius: 50%;
  }
  .outside-circle {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    .circle {
      --p:20;
      --b:22px;
      --c:#A969EA;
      --w:160px;

      width:var(--w);
      aspect-ratio:1;
      position:relative;
      display:inline-grid;
      place-content:center;
      font-size:25px;
      font-weight:bold;
      font-family:sans-serif;
    }
    .circle:before,
    .circle:after {
      content:"";
      position:absolute;
      border-radius:50%;
      z-index: 1;
    }
    .circle & .two:after {
      content:"";
      position:absolute;
      border-radius:50%;
      z-index: 3;
    }
    .circle:before {
      inset:0;
      background:
          radial-gradient(farthest-side,var(--c) 98%,#0000) top/var(--b) var(--b) no-repeat,
          conic-gradient(var(--c) calc(var(--p)*1%),#0000 0);
      -webkit-mask:radial-gradient(farthest-side,#0000 calc(99% - var(--b)),#000 calc(100% - var(--b)));
      mask:radial-gradient(farthest-side,#0000 calc(99% - var(--b)),#000 calc(100% - var(--b)));
    }
    .circle:after {
      inset:calc(50% - var(--b)/2);
      background:var(--c);
      transform:rotate(calc(var(--p)*3.6deg)) translateY(calc(50% - var(--w)/2));
    }
    @keyframes p {
      from{--p:0}
    }

  }
  .inside-circle {
    position: absolute;
    left: 20px;
    top: 20px;
    bottom: 20px;
    right: 20px;
    background-color: #ffffff;
    z-index: 20;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5em;
    color: var(--primary);
  }
  h3 {
    text-transform: uppercase;
    font-weight: bold;
  }
}
</style>
