<template>
  <div class="access-block-container">
    <img :src="accessBlock" alt="" class="access-block-icon">
    <p class="access-block-title">
      <t name="1.14.1" />
    </p>
    <p class="access-block-subtitle">
      <t name="1.14.2" />
    </p>
    <div class="timer-container">
      <div class="timer">{{ formattedTime }}</div>
      <div class="timer-text-container">
        <div class="day">
          <t name="1.14.3" />
        </div>
        <div class="hour">
          <t name="1.14.4" />
        </div>
        <div class="minute">
          <t name="1.14.5" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import accessBlock from 'assets/access-block.png'


export default {
  // props: {
  //   formattedTime: {
  //     type: String,
  //     required: true
  //   }
  // },
  data() {
    return {
      accessBlock
    }
  },
  computed: {
    timeRemaining() {
      return this.$store.state.app.timeRemaining;
    },
    formattedTime() {
      let days = Math.floor(this.timeRemaining / (60 * 60 * 24));
      let hours = Math.floor((this.timeRemaining % (60 * 60 * 24)) / (60 * 60));
      let minutes = Math.floor((this.timeRemaining % (60 * 60)) / 60);
      return `${String(days).padStart(2, '0')}:${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
    },
  }
}
</script>

<style scoped lang="scss" src="./modal-block.scss"></style>
