<!-- eslint-disable vue/multiline-html-element-content-newline -->
<template>
  <div class="modal-notification" :style="{top: show ? '0px' : '1000px'}">

    <div class="modal-notification__header">
      <button class="modal-notification__btn-close" @click="closeModal">
        <img :src="IconClose" alt="Icon close">
      </button>
    </div>

    <div class="modal-notification__body">
      <div class="modal-notification__icon-notify">
        <img :src="IconNotification" alt="Icon notification">
      </div>

      <h3> <t name="1.15.1" /></h3>
      <p class="modal-notification__content">
        <t name="1.15.2" />
      </p>

      <n-button class="user-default-button" @click="openPushSettings">
        <t name="1.15.3" />
      </n-button>
    </div>

  </div>
</template>

<script>
import IconNotification from '../../assets/icon-notification.png'
import IconClose from '../../assets/close-icon.svg'
import logo from 'assets/logo-small.svg'
import { NativeSettings, AndroidSettings, IOSSettings } from 'capacitor-native-settings';
import { Capacitor, } from '@capacitor/core'

export default {
  props: {
    show: {
      type: Boolean,
    }
  },
  data() {
    return {
      IconNotification,
      IconClose,
      logo,
    }
  },
  methods: {
    openPushSettings() {
      const platform = Capacitor.getPlatform()

      const platformSettings = {
        ios() {
          NativeSettings.openIOS({
            option: IOSSettings.App,
          });
        },
        android() {
          NativeSettings.openAndroid({
            option: AndroidSettings.AppNotification,
          });
        }
      }

      platformSettings[platform]()
    },

    closeModal() {
      this.$emit('close')  
    }

  }
}


</script>

<style scoped lang="scss" src="./modal-notification.scss"></style>
