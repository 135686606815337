<template>
  <div class="c-my-calendar">
    <n-loader :loading="$var('load')" />
    <div class="wrapper flex">
      <div class="left">
        <div class="month">
          <div class="month-prev btn" @click="decrease"><img :src="left" alt=""></div>
          <div class="month-title">{{ months[month] }} {{ year }}</div>
          <div class="month-next btn" @click="increase"><img :src="right" alt=""></div>
        </div>

        <div class="hint">
          <div v-for="(item, index) in types" :key="index" class="hint-item">
            <div class="circle" :style="'background-color:'+item.color+';'"></div>
            {{ item.title }}
          </div>
        </div>

        <div class="calendar">
          <div class="calendar-title">
            <div v-for="item in day" :key="item.id" class="day">{{ item }}</div>
          </div>
          <div class="wrap">
            <div v-for="(week, index) in calendar()" :key="index" class="week">
              <div
                class="calendar-item"
                :class="{'inactive': !week[1]?.index,}"
                :style="'background-color:'+getColor(workDays[week[1]?.numY])+';'"
                @click.ctrl.stop="ctrlAdd(getDate(week[1]?.index))"
                @click.stop="chooseDay(week[1]?.index)"
                @dblclick="changeWorkDay(!workDays[week[1].numY])">
                {{ week[1]?.index }}
              </div>
              <div
                class="calendar-item"
                :class="{'inactive': !week[2]?.index,}"
                :style="'background-color:'+getColor(workDays[week[2]?.numY])+';'"
                @click.ctrl.stop="ctrlAdd(getDate(week[2]?.index))"
                @click.stop="chooseDay(week[2]?.index)"
                @dblclick="changeWorkDay(!workDays[week[2].numY])">
                {{ week[2]?.index }}
              </div>
              <div
                class="calendar-item"
                :class="{'inactive': !week[3]?.index,}"
                :style="'background-color:'+getColor(workDays[week[3]?.numY])+';'"
                @click.ctrl.stop="ctrlAdd(getDate(week[3]?.index))"
                @click.stop="chooseDay(week[3]?.index)"
                @dblclick="changeWorkDay(!workDays[week[3].numY])">
                {{ week[3]?.index }}
              </div>
              <div
                class="calendar-item"
                :class="{'inactive': !week[4]?.index,}"
                :style="'background-color:'+getColor(workDays[week[4]?.numY])+';'"
                @click.ctrl.stop="ctrlAdd(getDate(week[4]?.index))"
                @click.stop="chooseDay(week[4]?.index)"
                @dblclick="changeWorkDay(!workDays[week[4].numY])">
                {{ week[4]?.index }}
              </div>
              <div
                class="calendar-item"
                :class="{'inactive': !week[5]?.index,}"
                :style="'background-color:'+getColor(workDays[week[5]?.numY])+';'"
                @click.ctrl.stop="ctrlAdd(getDate(week[5]?.index))"
                @click.stop="chooseDay(week[5]?.index)"
                @dblclick="changeWorkDay(!workDays[week[5].numY])">
                {{ week[5]?.index }}
              </div>
              <div
                class="calendar-item"
                :class="{'inactive': !week[6]?.index,}"
                :style="'background-color:'+getColor(workDays[week[6]?.numY])+';'"
                @click.ctrl.stop="ctrlAdd(getDate(week[6]?.index))"
                @click.stop="chooseDay(week[6]?.index)"
                @dblclick="changeWorkDay(!workDays[week[6].numY])">
                {{ week[6]?.index }}
              </div>
              <div
                class="calendar-item"
                :class="{'inactive': !week[0]?.index,}"
                :style="'background-color:'+getColor(workDays[week[0]?.numY])+';'"
                @click.ctrl.stop="ctrlAdd(getDate(week[0]?.index))"
                @click.stop="chooseDay(week[0]?.index)"
                @dblclick="changeWorkDay(!workDays[week[0].numY])">
                {{ week[0]?.index }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import left from 'assets/arrow-left-sheet.svg'
import right from 'assets/arrow-right-sheet.svg'

export default {
  name: 'CMyCalendar',
  props: {
    workdays: {
      type: [ Object, Array, ],
      required: true,
    },
    dbclick: {
      type: Boolean,
      default: false,
    },
    types: {
      type: Array,
      required: true,
    },
    date: {
      type: Date,
      default: () => new Date(),
    },
    isUser: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      left,
      right,
      month: new Date().getMonth(),
      year: new Date().getFullYear(),
      selectedDates: {},
      localeDay: {
        ru: [ 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс', ],
        kz: [ 'Дс', 'Сс', 'Ср', 'Бс', 'Жм', 'Сн', 'Жс', ],
      },
      dateForView: '',
      day: [],
      cDate: new Date,
      localeMonths: {
        ru: [ 'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь', ],
        kz: [ 'Қаңтар', 'Ақпан', 'Наурыз', 'Сәуір', 'Мамыр', 'Маусым', 'Шілде', 'Тамыз', 'Қыркүйек', 'Қазан', 'Қараша', 'Желтоқсан', ],
      },
      months: [],
      dlast: new Date(this.year, this.month + 1, 0).getDate(),
      isOpen: false,
      workDays: this.workdays,
      ctrlPressed: false,
    }
  },
  watch: {
    workdays() {
      this.workDays = this.workdays
    },
    cDate() {
      this.updateView()
    },
  },
  mounted() {
    window.addEventListener('keydown', this.handleKeyDown)
    window.addEventListener('keyup', this.handleKeyUp)
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown)
    window.removeEventListener('keyup', this.handleKeyUp)
  },
  created() {
    this.init()
  },
  methods: {
    handleKeyDown(event) {
      if (event.ctrlKey) {
        this.ctrlPressed = true
      }
    },
    handleKeyUp(event) {
      if (!event.ctrlKey) {
        this.ctrlPressed = false
      }
    },
    ctrlAdd(date) {
      this.cDate = null
      if (this.selectedDates[$app.date.format(date, 'date')]) {
        const obj = { ...this.selectedDates, }
        delete obj[$app.date.format(date, 'date')]
        this.selectedDates = obj
      } else {
        this.selectedDates = {
          ...this.selectedDates,
          [$app.date.format(date, 'date')]: date,
        }
      }
    },
    inSelected(day) {
      if (day) {
        const date = this.getDate(day)
        return !!this.selectedDates[$app.date.format(date, 'date')]
      }
    },
    getColor(value) {
      if (this.isUser) {
        value = value?.type
      }
      const type = $n.filter(this.types, (e) => {
        if (e.value === value) {
          return e
        }
      })
      return type[0]?.color || '#ffffff'
    },
    save() {
      let workDays = {}
      if (this.isUser) {
        $n.each(this.workDays, (e, i) => {
          if (i > 0) {
            if (Number(i) < 366) {
              workDays = {
                ...workDays,
                [Number(i)]: e,
              }
            }
          }
        })
        this.$emit('update:workdays', workDays)
      } else {
        this.$emit('update:workdays', this.workDays)
      }
      this.$emit('ready')
    },
    changeWorkDay(value, isManual) {
      if (Object.keys(this.selectedDates).length > 0) {
        $n.each(this.selectedDates, (e) => {
          if (this.isUser) {
            this.workDays = {
              ...this.workDays,
              [this.getNumberOfDay(e)]: {
                ...this.workDays[this.getNumberOfDay(e)],
                type: value,
              },
            }
          } else {
            this.workDays = {
              ...this.workDays,
              [this.getNumberOfDay(e)-1]: value,
            }
          }
        })
      } else {
        if (!isManual && this.dbclick) {
          this.workDays = {
            ...this.workDays,
            [this.getNumberOfDay(this.cDate)-1]: value,
          }
        } else {
          if (isManual) {
            if (this.isUser) {
              this.workDays = {
                ...this.workDays,
                [this.getNumberOfDay(this.cDate)]: {
                  ...this.workDays[this.getNumberOfDay(this.cDate)],
                  type: value,
                },
              }
            } else {
              this.workDays = {
                ...this.workDays,
                [this.getNumberOfDay(this.cDate)-1]: value,
              }
            }
          }
        }
      }
      this.updateView()
    },
    init() {
      const lang = $app.store.getter('app.lang') || 'ru'
      this.months = this.localeMonths[lang]
      this.day = this.localeDay[lang]
      this.cDate = this.date
      this.month = this.cDate.getMonth()
      this.year = this.cDate.getFullYear()
      this.updateView()
    },
    updateView() {
      if (this.cDate) {
        this.dateForView = {
          date: (this.cDate.getDate() <= 9 ? '0'+this.cDate.getDate(): this.cDate.getDate()) +
              ' '+ this.months[this.month].substr(0, 3) +'. '+ this.year,
          value: this.isUser ? this.workDays[this.getNumberOfDay(this.cDate)]?.type : this.workDays[this.getNumberOfDay(this.cDate)-1],
        }
      }
    },
    chooseDay(day) {
      if (!this.ctrlPressed) {
        this.selectedDates = {}
        if (day) {
          this.cDate = new Date(this.year, this.month, day)
          this.isOpen = false
        }
      }
    },
    getDate(day) {
      if (day) {
        return new Date(this.year, this.month, day)
      }
    },
    compareDate(day, sign = '=') {
      if (this.cDate) {
        if (sign === '=') {
          return $app.date.format(this.cDate, 'date') === $app.date.format(new Date(this.year, this.month, day), 'date')
        } else {
          if (sign === '>=') {
            return $app.date.format(this.cDate, 'date') >= $app.date.format(new Date(this.year, this.month, day), 'date')
          } else {
            return $app.date.format(this.cDate, 'date') <= $app.date.format(new Date(this.year, this.month, day), 'date')
          }
        }
      }
    },
    getNumberOfDay(today) {
      const startOfYear = new Date(today.getFullYear(), 0, 0)
      const diff = today - startOfYear

      const oneDay = 1000 * 60 * 60 * 24
      return Math.floor(diff / oneDay)
    },
    calendar: function() {
      let weeks = {}
      let week = 0
      let days = {}
      const dlast = new Date(this.year, this.month + 1, 0).getDate()

      for (let i = 1; i <= dlast; i++) {
        if (!this.isUser) {
          days = {
            ...days,
            [new Date(this.year, this.month, i).getDay()]: { index: i, numY: Number(this.getNumberOfDay(new Date(this.year, this.month, i)) - 1), },
          }
        } else {
          days = {
            ...days,
            [new Date(this.year, this.month, i).getDay()]: { index: i, numY: Number(this.getNumberOfDay(new Date(this.year, this.month, i))), },
          }
        }
        if (new Date(this.year, this.month, i).getDay() === 0) {
          weeks = {
            ...weeks,
            [week]: days,
          }
          week+=1
          days = {}
        }
      }
      if (Object.keys(days).length !== 0) {
        weeks = {
          ...weeks,
          [week]: days,
        }
      }

      return weeks
    },
    decrease() {
      this.month--
      if (this.month < 0) {
        this.month = 12
        this.month--
        this.year--
      }
    },
    increase() {
      this.month++
      if (this.month > 11) {
        this.month = -1
        this.month++
        this.year++
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.c-my-calendar {
  .n-tabs {
    justify-content: center;
    margin-top: 20px;

    &::v-deep {
      & > .n-tab {
        margin: 0 14px;
        padding: 0;
        color: #000000;
        cursor: pointer;
        flex-shrink: 0;
        min-width: 250px;
        min-height: 40px;
        font-size: 1em;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 3px solid var(--primary);
        border-radius: 24px;
        background-color: #FFFFFF;

        &.active {
          background: var(--primary);
          border: 3px solid var(--primary);
          color: #fff;
        }
      }
    }
  }

  .wrapper {
    margin-top: 30px;
    min-height: 400px;
    padding: 30px;
    background-color: #FFFFFF;
    border-radius: 12px;

    &.flex {
      display: flex;
      justify-content: space-between;

      .left {
        .n-button {
          margin-top: 20px;
          min-width: 240px;
          color: #FFFFFF;
          border: 3px solid var(--primary);
          border-radius: 24px;
          background-color: var(--primary);
        }
      }

      .right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding-top: 140px;

        .n-button {
          margin-top: 20px;
          min-width: 240px;
          color: #FFFFFF;
          border: 3px solid var(--primary);
          border-radius: 24px;
          background-color: var(--primary);
        }
      }
    }
  }

  .title {
    font-weight: 700;
    font-size: 16px;
    color: #000;
    margin-bottom: 18px;
  }

  .item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    & + .title {
      margin-top: 30px;
    }

    &-title {
      font-weight: 400;
      font-size: 16px;
      color: #000;
      width: 180px;
    }

    &-text {
      background: #FFFFFF;
      border: 1px solid #8A8888;
      border-radius: 2px;
      width: 490px;
      padding: 0 12px;
      display: flex;
      align-items: center;
      height: 30px;
      position: relative;
    }
  }

  .change-color {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    &::after {
      content: '';
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translate(0, -50%);
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid var(--primary);
    }
  }

  .n-input {
    width: 490px;
  }

  .logo {
    width: 100px;

    img {
      max-width: 100%;
    }
  }


  .buttons {
    margin: 40px 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;


    .n-button {
      min-width: 240px;
      color: #FFFFFF;
      border: 3px solid var(--primary);
      border-radius: 24px;
      background-color: var(--primary);

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .hint {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    flex-wrap: wrap;

    &-item {
      font-weight: 400;
      font-size: 16px;
      color: #000;
      display: flex;
      align-items: center;
      margin-right: 30px;
      width: 33.3333333%;

      &:last-child {
        margin-right: 0;
      }
    }

    .circle {
      margin-right: 8px;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      background: #FFF;
      border: 1px solid #CFCECE;

      &.green {
        background: #64C038;
        border: 1px solid #64C038;
      }
    }
  }
  .week {
    display: flex;
    align-items: center;
  }

  .calendar {
    width: 700px;

    &-title {
      display: flex;
      margin-bottom: 8px;

      .day {
        padding-left: 10px;
        width: 100px;
        font-weight: 400;
        font-size: 16px;
        color: #777676;
      }
    }

    &-item {
      padding: 5px;
      width: 100px;
      height: 82px;
      border: 1px solid #777676;
      border-radius: 15px;
      &.vac {
        background-color: #64C038;
      }
      &.selected {
        border: 3px solid var(--primary);
      }
      &.inactive {
        background-color: #d8d8d8 !important;
      }
    }
  }

  .month {
    margin-bottom: 20px;
    background: var(--primary);
    border-radius: 8px;
    width: 200px;
    height: 45px;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-image: linear-gradient(90deg, #6e1a8d 0%, #b010da 100%);
    color: #FFF;
    .month-prev {
      transform: rotate(-180deg);
    }
    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }

  .form-wrap {
    padding: 20px;
    background-color: #FFFFFF;
    border-radius: 12px;
    width: 560px;

    & > .text {
      margin-bottom: 20px;
    }

    .text {
      font-weight: 400;
      font-size: 16px;
      color: #000;
    }
  }

  .custom-checkbox {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;

    &:last-child {
      margin-bottom: 0;
    }

    input[type="checkbox"] {
      position: absolute;
      bottom: 0;
      right: 0;
      top: 0;
      left: 0;
      opacity: 0;

      &:checked {
        & + .checkbox {
          background: #64C038;
        }
      }
    }

    .checkbox {
      margin-right: 15px;
      width: 20px;
      height: 20px;
      background: #FFF;
      border-radius: 50%;
      border: 1px solid #CFCECE;
      transition: all .3s ease-in-out;
    }
  }
}
</style>
