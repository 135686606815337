<template>
  <div class="c-user-edit">
    <n-loader :loading="$var('load')"></n-loader>
    <div class="container">
      <div class="header">
        <img :src="imgBackrow" alt="" @click="closeForm">
        <h1>{{ title }}</h1>
      </div>
      <div class="input-place">
        <n-input v-model="newValue" :placeholder="'Ввведите '+title" />
      </div>
    </div>
    <div class="button-place">
      <n-button class="user-default-button" :disabled="isBtnDisabled" @click="editUser">Сохранить</n-button>
    </div>
  </div>
</template>

<script>
import imgBackrow from 'assets/backrow.svg'
export default {
  name: 'CUserEdit',
  props: {
    title: {
      type: String,
      required: true,
    },
    field: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      newValue: '',
      isBtnDisabled: true,
      imgBackrow,
    }
  },
  watch: {
    newValue() {
      this.isBtnDisabled = false
    },
  },
  created() {},
  methods: {
    editUser() {
      this.$var('load', true)
      $api.users.edit($app.auth.user().id, { [this.field]: this.newValue, }).then((response) => {
        $app.store.action('app.updateUser').finally(() => {})
        this.closeForm()
      }).finally(() => {
        this.$var('load', false)
      })
    },
    closeForm() {
      this.$emit('update:show', false)
    },
  },
}
</script>

<style lang="scss" scoped>
.c-user-edit {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 1001;
  padding-top: 50px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .email-code-input {
    margin-top: 25px;
     h3 {
       text-align: center;
       font-size: 1.3em;
       margin-bottom: 20px;
     }
  }

  .header {
    display: flex;
    align-items: center;
    h1 {
      font-size: 1.5em;
      font-weight: normal;
      margin: 0 auto;
    }
  }
  .input-place {
    margin-top: 50px;
  }
  .button-place {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 30px;
    .user-default-button {
      width: 200px;
      height: 44px;
      background-color: var(--primary);
      border-radius: 8px;
      color: #ffffff;
      font-size: 1.1em;
      font-weight: bold;
    }
  }
}
</style>
