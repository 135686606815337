<!-- eslint-disable max-len -->
<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="c-sidebar">
    <n-loader :loading="$var('load')" />
    <div class="danger" v-html="$var('message')"></div>
    <div class="logo-place">
      <div class="image-place">
        <img :src="imgLogo" alt="">
      </div>
    </div>
    <div class="sidebar-links">
      <n-link
        class="sidebar-link" :to="{ name: 'desktop.index' }" :class="{ 'active': routeName === 'desktop.index' }"
        qa-id="main-page-statistics-page-open-button">
        <img class="icon" :src="routeName === 'desktop.index' ? imgStatsActive : imgStats" alt="">
        <p :class="{ 'active': routeName === 'desktop.index' }">Статистика</p>
      </n-link>
      <n-link
        class="sidebar-link" :to="{ name: 'desktop.users' }" :class="{ 'active': routeName === 'desktop.users' }"
        qa-id="main-page-users-page-open-button">
        <img class="icon" :src="routeName === 'desktop.users' ? imgUsersActive : imgUsers" alt="">
        <p :class="{ 'active': routeName === 'desktop.users' }">Пользователи</p>
      </n-link>
      <n-link
        class="sidebar-link" :to="{ name: 'desktop.limits' }"
        :class="{ 'active': routeName === 'desktop.limits' }">
        <img class="icon" :src="routeName === 'desktop.limits' ? imgUsersActive : imgUsers" alt="">
        <p :class="{ 'active': routeName === 'desktop.limits' }">Лимиты</p>
      </n-link>
      <n-link
        v-if="company.ttIntegration" class="sidebar-link" :to="{ name: 'desktop.workdays' }"
        :class="{ 'active': routeName === 'desktop.workdays' }" qa-id="main-page-users-page-open-button">
        <img class="icon" :src="routeName === 'desktop.workdays' ? visitsActive : visits" alt="">
        <p :class="{ 'active': routeName === 'desktop.workdays' }">Посещения</p>
      </n-link>
      <n-link
        v-if="company.ttIntegration" class="sidebar-link" :to="{ name: 'desktop.filials' }"
        :class="{ 'active': routeName === 'desktop.filials' }" qa-id="main-page-users-page-open-button">
        <img class="icon" :src="routeName === 'desktop.filials' ? filialsActive : filials" alt="">
        <p :class="{ 'active': routeName === 'desktop.filials' }">Филиалы</p>
      </n-link>
      <n-link
        v-if="company.needCivilLegal || company.needFCBVerification" class="sidebar-link"
        :to="{ name: 'desktop.eds' }" :class="{ 'active': routeName === 'desktop.eds' }"
        qa-id="main-page-users-page-open-button">
        <img class="icon" :src="routeName === 'desktop.eds' ? documentsActive : documents" alt="">
        <p :class="{ 'active': routeName === 'desktop.eds' }">Документы</p>
      </n-link>
      <n-link
        v-if="needPermanentOtp" class="sidebar-link"
        :to="{ name: 'desktop.eds-documents' }" :class="{ 'active': routeName === 'desktop.eds-documents' }"
        qa-id="main-page-users-page-open-button">
        <img class="icon" :src="routeName === 'desktop.eds-documents' ? documentsActive : documents" alt="">
        <p :class="{ 'active': routeName === 'desktop.eds-documents' }">Документы</p>
      </n-link>
      <n-link
        v-if="company.integrationType === 'EXCEL'" class="sidebar-link" :to="{ name: 'desktop.integration' }"
        :class="{ 'active': routeName === 'desktop.integration' }" qa-id="main-page-users-page-open-button">
        <img class="icon" :src="routeName === 'desktop.integration' ? integrationTabActive : integrationTab" alt="">
        <p :class="{ 'active': routeName === 'desktop.integration' }">Интеграция</p>
      </n-link>
      <n-link
        class="sidebar-link" :to="{ name: 'desktop.reports' }"
        :class="{ 'active': routeName === 'desktop.reports' }" qa-id="main-page-support-page-open-button">
        <img class="icon" :src="routeName === 'desktop.reports' ? documentsActive : documents" alt="">
        <p :class="{ 'active': routeName === 'desktop.reports' }">Отчеты</p>
      </n-link>
      <n-link
        class="sidebar-link" :to="{ name: 'desktop.support' }"
        :class="{ 'active': routeName === 'desktop.support' }">
        <img class="icon" :src="routeName === 'desktop.support' ? imgSupportActive : imgSupport" alt="">
        <p :class="{ 'active': routeName === 'desktop.support' }">Служба поддержки</p>
      </n-link>
      <div class="side-bar-logout" @click="$app.auth.logout()"><img :src="imgLogout" alt="">Выйти</div>
    </div>
  </div>
</template>

<script>
import imgLogo from 'assets/logo.png'
import imgFaq from 'assets/sidebar/faq.svg'
import imgStats from 'assets/sidebar/stats.svg'
import imgSupport from 'assets/sidebar/support.svg'
import imgUsers from 'assets/sidebar/users.svg'
import imgFaqActive from 'assets/sidebar/faq-active.svg'
import imgStatsActive from 'assets/sidebar/stats-active.svg'
import imgSupportActive from 'assets/sidebar/support-active.svg'
import imgUsersActive from 'assets/sidebar/users-active.svg'
import imgLogout from 'assets/profile/logout.svg'
import imgTimeTracker from 'assets/mobile-appbar/mobile-time-tracker.svg'
import visits from 'assets/sidebar/visits.svg'
import visitsActive from 'assets/sidebar/visits-active.svg'
import filials from 'assets/sidebar/filial.svg'
import filialsActive from 'assets/sidebar/filial-active.svg'
import documents from 'assets/sidebar/documents.svg'
import documentsActive from 'assets/sidebar/documents-active.svg'
import integrationTab from 'assets/sidebar/documents.svg'
import integrationTabActive from 'assets/sidebar/documents-active.svg'



export default {
  name: 'CSidebar',
  data() {
    return {
      imgLogo,
      imgStats,
      imgFaq,
      imgSupport,
      imgUsers,
      imgStatsActive,
      imgFaqActive,
      imgSupportActive,
      imgUsersActive,
      imgLogout,
      imgTimeTracker,
      visits,
      visitsActive,
      filials,
      filialsActive,
      documents,
      documentsActive,
      integrationTab,
      integrationTabActive,
      company: {},
      needPermanentOtp: false,
    }
  },
  computed: {
    routeName() {
      return this.$route.name
    },
  },
  watch: {},
  created() {
  },
  mounted() {
    this.loadCompany()
  },
  methods: {
    loadCompany() {
      this.$var('load', true)
      $api.companies.get($app.auth.user().selectedCompany).then((response) => {
        this.company = response.data.content
        this.$store.commit('app/setCompany', this.company)
        this.checkNeedPermanentOtp(this.company.bin)
      }).catch((response) => {
        console.log(response);
        $app.ui.notify('Ошибка загрузки компании', 'danger')
        // this.$var('message', $app.secure.clean(response.response.data.message.replace(/\n/g, '<br>')))
      }).finally(() => {
        this.$var('load', false)
      })
    },
    checkNeedPermanentOtp(bin) {
      if (bin === "081040013276") {
        this.needPermanentOtp = true
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.c-sidebar {
  position: fixed;
  z-index: 10;
  height: 100vh;
  left: 0;
  border-bottom: 0;
  bottom: 0;
  top: 0;
  padding-left: 15px;
  min-width: 220px;
  background-color: #F1F5F8;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #F1F5F8;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--secondary);
    border-radius: 20px;
    border: 1px solid #F1F5F8;
  }

  .logo-place {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: fixed;
    z-index: 10;
    left: 0;
    background: #F1F5F8;

    .image-place {
      width: 188px;
      height: 36px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .title {
      font-size: 1.7em;
      color: var(--primary);
      font-weight: bold;
      margin-bottom: 10px;
    }
  }

  .sidebar-links {
    padding-top: 110px;
    padding-bottom: 15px;
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    flex-shrink: 0;

    .sidebar-link {
      text-decoration: none;
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 70px;
      min-width: 170px;
      border-radius: 12px;
      margin-bottom: 15px;

      &.active {
        background-color: var(--primary);
      }

      &:last-child {
        margin-bottom: 0;
      }

      p {
        color: var(--secondary);
        font-size: 1.3em;
        min-width: 125px;
        max-width: 145px;
        margin-left: 15px;

        &.active {
          color: #ffffff;
        }
      }
    }

    .side-bar-logout {
      cursor: pointer;
      display: flex;
      align-items: center;
      color: red;
      background-color: rgba(255, 0, 0, 0.1);
      width: 100px;
      z-index: 10;
      padding: 20px 0 20px 5px;
      width: 210px;
      border-radius: 8px;

      img {
        margin-right: 15px;
      }

      &:hover {
        background-color: rgba(255, 0, 0, 0.4);
      }
    }
  }
}

.danger {
  color: rgb(236, 90, 90);
}
</style>
