<!-- eslint-disable vue/multiline-html-element-content-newline -->
<template>
  <div class="modal-notification" :style="{top: show ? '0px' : '1000px'}">

    <div class="modal-notification__header">
      <!-- <button class="modal-notification__btn-close" @click="closeModal">
        <img :src="IconClose" alt="Icon close">
      </button> -->
    </div>

    <div class="modal-notification__body">
      <div class="modal-notification__icon-notify">
        <img :src="UpdateIcon" alt="Icon notification">
      </div>

      <p class="modal-notification__content">
        <t name="1.14.6" />
      </p>

      <n-button class="user-default-button" @click="openBrowser">
        <t name="1.14.7" />
      </n-button>
    </div>

  </div>
</template>

<script>
import UpdateIcon from '../../assets/update-icon.png'
import IconClose from '../../assets/close-icon.svg'
import logo from 'assets/logo-small.svg'
import { Browser } from '@capacitor/browser';


export default {
  props: {
    show: {
      type: Boolean,
    }
  },
  data() {
    return {
      UpdateIcon,
      IconClose,
      logo,
    }
  },
  methods: {
    async openBrowser() {
      await Browser.open({ url: 'https://onelink.to/h7stv2' });
    },

    closeModal() {
      this.$emit('close')  
    }
  }
}
</script>

<style scoped lang="scss" src="./modal-notification.scss"></style>

